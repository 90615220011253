import api from '@/services/api';

const url = 'regulations';

const findAll = () => api.get(`${url}/find-all-regulation`);

const findAllActive = (regulation) => api.get(`${url}/find-all-active-regulations`, regulation);

const findByID = (id) => api.get(`${url}/?id=${id}`);

const create = (regulation) => api.post(`${url}/create`, regulation);

const updateById = (id, regulation) => api.patch(`${url}/update-by-id/?id=${id}`, regulation);

export default {
  findAll, create, updateById, findByID, findAllActive,
};
