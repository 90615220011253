<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="tableHeaders"
      :items="regulation"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Regulamentações e Carências</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openDialog"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.status === 1"
          value
          disabled
          class="primary--text"
        />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseOnlyDate }}</p>
      </template>
      <template #item.updatedAt="{ item }">
        <p>{{ item.updatedAt | parseOnlyDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-regulation
        v-if="dialog"
        :populate-with="regulationToEdit"
        @update-list="loadRegulationList"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import RegulationService from '../../../services/regulation.service';

export default {
  name: 'RegulationList',
  components: {
    'form-regulation': () => import('./Form'),
  },
  data() {
    return {
      tableHeaders: [
        {
          text: 'Ativo', align: 'start', sortable: false, value: 'active',
        },
        { text: 'Título', sortable: true, value: 'title' },
        { text: 'Author', value: 'createdAutor', sortable: true },
        { text: 'Data de Inicio', value: 'createdAt', sortable: true },
        { text: 'Data Atualização', value: 'updatedAt', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      regulation: [],
      search: '',
      dialog: false,
      regulationToEdit: {},
    };
  },
  created() {
    this.loadRegulationList();
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.regulationToEdit = {};
    },
    editItem(item) {
      this.regulationToEdit = item;
      this.openDialog();
    },
    async loadRegulationList() {
      this.regulation = await RegulationService.findAll();
    },
  },
};
</script>
